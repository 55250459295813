import Model from '@/dc-it/models/Model'
import Field from '@/dc-it/types/Field'
import Customer from '@/dc-it/models/Customer'
import AccountType from '@/dc-it/models/AccountType'
import Relation from '@/dc-it/types/Relation'
import helpers from '@/dc-it/services/helpers'

export default class Account extends Model {
    endpoint = 'accounts';

    related = ['customer', 'account_type'];

    actions = [
      {
        class: 'btn-info',
        disabled() {
          return false
        },
        text: '',
        tooltip: 'Ver movimientos',
        icon: 'CodeIcon',
        action: 'accountTransactionsView',
        params(model) {
          return {
            id: model.getAttr('id'),
          }
        },
      },
    ]

    fields = [
      new Field('id', 'Código').hide(),
      new Relation('customer', 'Cliente', Customer, 'first_name')
        .applyMask((_val, model) => `${model.getAttr('first_name')} ${model.getAttr('last_name')}`),
      new Relation('account_type', 'Tipo de cuenta', AccountType, 'name'),
      new Field('balance', 'Saldo', 'number').applyMask(val => `$ ${helpers.numberFormat(val)}`),
      // new Field('initial_balance', 'Saldo inicial', 'number'),
    ];

    clone=() => Account
}
